var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"sm":"12","cols":"12"}},[_c('h2',{staticClass:"mb-0",staticStyle:{"font-size":"25px","font-weight":"550"}},[_vm._v(" "+_vm._s(_vm.$t("welcome to annual score "))+" ")]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}}),(_vm.listMajorGeneration.length > 0)?_c('v-row',_vm._l((_vm.listMajorGeneration),function(item,i){return _c('v-col',{key:i,attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#fccece","dark":"","max-width":"500"},on:{"click":function($event){return _vm.$router.push({
                      name: 'AnnualScore',
                      params: {
                        id: item.id,
                        name_en: item.name_en,
                        major_id: item.major_id,
                      },
                    })}}},[_c('v-img',{attrs:{"width":"500","height":"130","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fbg5.jpg?alt=media&token=dd35fc06-237d-4c8e-a45a-5ecc18137f6e"}},[_c('div',{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-h5",staticStyle:{"color":"#ffff"}},[_vm._v(" "+_vm._s(item.name_en)+" ")])]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'major-admin/annual/gen/score/' +
                        item.id +
                        '&' +
                        item.name_en +
                        '&' +
                        item.major_id,"tag":"button"}})],1)],1)],1)}),1):_c('v-row',{staticClass:"mb-5",attrs:{"align":"center","justify":"center"}},[_c('div',[_c('v-img',{staticClass:"center",attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"}}),_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" There is no data for you to see yet ")]),_c('div',{staticClass:"text-h6 text-center"},[_vm._v(" There is no Generation has been created yet !!!!!! ")])],1)])],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }